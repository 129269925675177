import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/landing';

import CustomerReviews from 'components/content-blocks/customer-reviews/customer-reviews';
import FeaturesGrid from 'components/content-blocks/features-grid';
import Highlight from 'components/content-blocks/highlight';
import { SignUpTrialLength } from 'components/forms/constants';
import Yoast from 'components/globals/yoast';
import { HeroDefaultSignup } from 'components/hero/default-signup';

export default function Refer() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: {slug: {eq: "refer"}}) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                background_desktop_image {
                  source_url
                }
                background_mobile_image {
                  source_url
                  alt_text
                }
                title
                sub_title
                copy
                signup {
                  launch_modal_button_text
                  modal_title
                  modal_submit_button_text
                  unique_id
                  legal_text
                }
              }
              cards_1 {
                header {
                  title
                }
                card {
                  image {
                    source_url
                  }
                  title
                }
              }
              features_grid {
                title
                sub_title
                buttons {
                  title
                  body
                  alt_text
                  icon_width
                  icon_from_library
                  mobile_screenshot {
                    source_url
                  }
                  desktop_screenshot {
                    source_url
                  }
                }
              }
            }
          }
        }
      }
      wordpressAcfOptions {
        options {
          customer_reviews {
            title
            sub_title
            customer {
              name
              company
              quote
              image {
                source_url
              }
            }
          }
        }
      }
    }
`);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const yoastData = data.allWordpressPage.edges[0].node.yoast_head_json;
  const customerReviews = data.wordpressAcfOptions.options.customer_reviews;
  const hero = pageContext.hero;
  const cardsData = pageContext.cards_1;
  const featuresGrid = pageContext.features_grid;
  const trialLength = SignUpTrialLength.REFER_TRIAL_LENGTH;

  return (
    <Layout legalText={ hero.signup.legal_text } trialLength={ trialLength }>
      <Yoast { ...yoastData } />
      <div className="refer-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultSignup { ...hero } trialLength={ trialLength } />
        <Highlight { ...cardsData } hasTitle hasImage />
        <FeaturesGrid { ...featuresGrid[0] } />
        <CustomerReviews { ...customerReviews } hasImage hasAwards />
      </div>
    </Layout>
  );
}